<!--
 * @Author: SongYijie
 * @Date: 2020-03-23 16:06:43
 * @LastEditors: SongYijie
-->
<template>
  <div class='protocol'>
    <div class="img-wrap">
      <ImagePreview :imgUrl="'https://xiyk.oss-cn-hangzhou.aliyuncs.com/protocol-1.png'" alt="protocol" />
      <ImagePreview :imgUrl="'https://xiyk.oss-cn-hangzhou.aliyuncs.com/protocol-2.png'" alt="protocol" />
      <ImagePreview :imgUrl="'https://xiyk.oss-cn-hangzhou.aliyuncs.com/protocol-3.png'" alt="protocol" />
      <ImagePreview :imgUrl="'https://xiyk.oss-cn-hangzhou.aliyuncs.com/protocol-4.png'" alt="protocol" />
      <ImagePreview :imgUrl="'https://xiyk.oss-cn-hangzhou.aliyuncs.com/protocol-5.png'" alt="protocol" />
      <ImagePreview :imgUrl="'https://xiyk.oss-cn-hangzhou.aliyuncs.com/protocol-6.png'" alt="protocol" />
      <ImagePreview :imgUrl="'https://xiyk.oss-cn-hangzhou.aliyuncs.com/protocol-7.png'" alt="protocol" />
      <ImagePreview :imgUrl="'https://xiyk.oss-cn-hangzhou.aliyuncs.com/protocol-8.png'" alt="protocol" />
    </div>
    <div class='footer-bt'>
      <mt-button type='danger' size='large' @click.native='handleNext'>去签名</mt-button>
    </div>
  </div>
</template>

<script>
  import ImagePreview from '@components/image-preview';
  export default {
    data() {
      return {}
    },
    components: {
      ImagePreview
    },
    mounted() {},
    methods: {
      handleNext() {
        this.$router.replace('/individual-business/sign');
      }
    }
  }
</script>

<style lang='less' scoped>
.img-wrap {
  width: 100%;
  height: 100%;
  margin-bottom: 24vw;
  > img {
    width: 100%;
    height: 100%;
  }
}
</style>